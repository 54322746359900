(function ($) {
  function async_load () {
    $('.booking-search-form').each(function () {
      getSearchForm($(this));
    });
  }

  if (window.attachEvent) window.attachEvent('onload', async_load);else window.addEventListener('load', async_load, false);

  $('.booking-search-form').each(function () {
    var parksElement = $(this).find('#edit-park');
    var accommodationElement = $(this).find('#edit-accommodation');
    var durationElement = $(this).find('#edit-duration');
    var targetForm = $(this);

    $(parksElement).change(function () {
      getSearchForm( $(this).closest('.booking-search-form'));
    });

    $(accommodationElement).change(function () {
      getSearchForm( $(this).closest('.booking-search-form'));
    });

    $(durationElement).change(function () {
      getSearchForm( $(this).closest('.booking-search-form'));
    });


    $(targetForm).find('.datepicker-inline').on('click', function (e) {
      e.stopPropagation();
    });
  });

  function getSearchForm(target) {
    var host = target.data('host');
    var parksElement = target.find('#edit-park');
    var adultsElement = target.find('#edit-adults');
    var childrenElement = target.find('#edit-children');
    var accommodationElement = target.find('#edit-accommodation');
    var durationElement = target.find('#edit-duration');
    var arrivalElement = target.find('#arrival--search_form');

    $.getJSON(host + '/jsonapi/searchform?filter[park]=' + parksElement.val() + '&filter[accommodation]=' + accommodationElement.val() + '&filter[duration]=' + durationElement.val(), function (response) {
      var selectedPark = parksElement.val();
      parksElement.empty();
      $.each(response.data.attributes.parks, function (key, entry) {
        parksElement.append($('<option></option>').attr('value', key).text(entry));
      });
      if (Object.keys(response.data.attributes.parks).length === 1) {
        parksElement.val(Object.keys(response.data.attributes.parks)[0]);
      } else {
        parksElement.prepend('<option value="" selected="selected">Select park</option>');
        parksElement.val(selectedPark);
      }

      var selectedAccommodation = accommodationElement.val();
      accommodationElement.prop("disabled", true);
      accommodationElement.empty();
      $.each(response.data.attributes.accommodation, function (key, entry) {
        accommodationElement.append($('<option></option>').attr('value', key).text(entry));
      });
      if (Object.keys(response.data.attributes.accommodation).length === 1) {
        accommodationElement.val(Object.keys(response.data.attributes.accommodation)[0]);
        accommodationElement.removeAttr("disabled");
      } else {
        accommodationElement.prepend('<option value="" selected="selected">Select accommodation</option>');
        accommodationElement.val(selectedAccommodation);
        if (Object.keys(response.data.attributes.accommodation).length > 0) {
          accommodationElement.removeAttr("disabled");
        }
      }

      var selectedDuration = durationElement.val();
      durationElement.prop("disabled", true);
      durationElement.empty();
      $.each(response.data.attributes.duration, function (key, entry) {
        durationElement.append($('<option></option>').attr('value', key).text(entry));
      });
      if (Object.keys(response.data.attributes.duration).length === 1) {
        durationElement.val(Object.keys(response.data.attributes.duration)[0]);
        durationElement.removeAttr("disabled");
      } else {
        durationElement.prepend('<option value="" selected="selected">Select duration</option>');
        durationElement.val(selectedDuration);
        if (Object.keys(response.data.attributes.duration).length > 0) {
          durationElement.removeAttr("disabled");
        }
      }

      var selectedAdults = adultsElement.val();
      adultsElement.empty();
      $.each(response.data.attributes.adults, function (key, entry) {
        adultsElement.append($('<option></option>').attr('value', key).text(entry));
      });
      if (selectedAdults) {
        adultsElement.val(selectedAdults);
      } else {
        adultsElement.val(Object.keys(response.data.attributes.adults)[0]);
      }

      var selectedChildren = childrenElement.val();
      childrenElement.empty();
      $.each(response.data.attributes.children, function (key, entry) {
        childrenElement.append($('<option></option>').attr('value', key).text(entry));
      });
      if (selectedChildren) {
        childrenElement.val(selectedChildren);
      } else {
        childrenElement.val(Object.keys(response.data.attributes.children)[0]);
      }

      var myDatepicker = target.find('.js-booking-widget-arrival').datepicker().data('datepicker');
      myDatepicker.clear();
      if (Object.keys(response.data.attributes.arrival).length > 0) {
        arrivalElement.removeAttr("disabled");
        initDatepicker(target, response.data.attributes.arrival);
      } else {
        arrivalElement.prop("disabled", true);
      }
    });
  }

  function initDatepicker(target, arrivalDates) {
    var $dateInputs = target.find('.js-booking-widget-arrival');
    var onSelect = function onSelect(formattedDate, date, instance) {
      var $element = instance.$el;
      var $datepicker = $element.parent().find('.datepicker-inline');

      $element.attr('value', formattedDate);

      $datepicker.removeClass('active');

      $element.trigger('change');
      $element.blur();
    };

    $dateInputs.each(function () {
      var $this = $(this);
      var dateId = $(this).attr('id');

      var availableDates = arrivalDates.map(function (date) {
        return date * 1000;
      }).sort(function (a, b) {
        return a - b;
      });

      var minDate = availableDates.length > 0 ? new Date(availableDates[0]) : new Date();

      var maxDate = availableDates.length > 0 ? new Date(availableDates[availableDates.length - 1]) : new Date();

      var onRenderCell = function onRenderCell(date, cellType) {
        if (cellType === 'day') {
          var dt = date.getTime() - date.getTimezoneOffset() * 60000;
          var disabled = availableDates.indexOf(dt) < 0;
          return { disabled: disabled };
        }
      };

      var datepickerOptions = {
        dateFormat: 'dd-mm-yyyy',
        inline: true,
        language: 'en',
        minDate: minDate,
        maxDate: maxDate,
        onRenderCell: onRenderCell,
        onSelect: onSelect
      };

      $this.datepicker(datepickerOptions);

      if ($this.data('datepicker')) {
        $this.on('click', function (e) {
          if ($this.parent().find('.datepicker-inline').hasClass('active')) {
            $this.parent().find('.datepicker-inline').removeClass('active');
          } else {
            $this.parent().find('.datepicker-inline').addClass('active');
            e.stopPropagation();
          }
        });
      }
    });
  }
})(jQuery);
